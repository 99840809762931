import styled from 'styled-components';

import { defaultDividerStyles, DividerProps } from '@Components/atoms/Divider/Divider.styles';
import { h1Styles, h2Styles, h3Styles, h4Styles, h5Styles } from '@Components/atoms/Headline/Headline.styles';
import { LinkColor, LinkSize, LinkWeight } from '@Components/atoms/Link/Link';
import { defaultLinkStyles, LinkProps } from '@Components/atoms/Link/Link.styles';
import { defaultTextStyles, TextProps } from '@Components/atoms/Text/Text.styles';

import { TextAlign, TextColor, TextSize, TextTransform, TextWeight } from '@Helpers/types/text';

interface HTMLTextProps {
  h1Transform?: TextTransform;
  h1Color?: TextColor;
  h2Transform?: TextTransform;
  h2Color?: TextColor;
  h3Transform?: TextTransform;
  h3Color?: TextColor;
  h4Transform?: TextTransform;
  h4Color?: TextColor;
  h5Transform?: TextTransform;
  h5Color?: TextColor;
  linkProps?: LinkProps;
  textPros?: TextProps;
  dividerProps?: DividerProps;
}

export const Root = styled.span<HTMLTextProps>`
  h1 {
    ${(props) => h1Styles(props.h1Transform || TextTransform.Unset, props.h1Color || TextColor.Secondary)};
    margin-bottom: ${(props) => props.theme.space.default_16};
  }

  h2 {
    ${(props) => h2Styles(props.h2Transform || TextTransform.Unset, props.h2Color || TextColor.Secondary)};
    margin-bottom: ${(props) => props.theme.space.default_16};
  }

  h3 {
    ${(props) => h3Styles(props.h3Transform || TextTransform.Unset, props.h3Color || TextColor.Secondary)};
    margin-bottom: ${(props) => props.theme.space.default_16};
  }

  h4 {
    ${(props) => h4Styles(props.h4Transform || TextTransform.Unset, props.h4Color || TextColor.Secondary)};
    margin-bottom: ${(props) => props.theme.space.default_16};
  }

  h5 {
    ${(props) => h5Styles(props.h5Transform || TextTransform.Unset, props.h5Color || TextColor.Secondary)};
    margin-bottom: ${(props) => props.theme.space.default_16};
  }

  p {
    ${(props) =>
      defaultTextStyles(
        props.textPros || {
          $weight: TextWeight.Light,
          $color: TextColor.Primary,
          $size: TextSize.Medium,
          $textAlign: TextAlign.Initial,
          $textTransform: TextTransform.Unset,
          $isTruncated: false,
          $inline: false,
        }
      )};
    margin-bottom: ${(props) => props.theme.space.large_32};
  }

  ol {
    list-style: decimal inside;
    margin-bottom: ${(props) => props.theme.space.default_16};
  }

  ul {
    list-style: disc inside;
    margin-bottom: ${(props) => props.theme.space.default_16};
  }

  a {
    ${(props) =>
      defaultLinkStyles(
        props.linkProps || {
          $color: LinkColor.Link,
          $weight: LinkWeight.Light,
          $size: LinkSize.Medium,
          $align: TextAlign.Left,
          $transform: TextTransform.Unset,
          $hideUnderline: false,
        }
      )};
  }

  hr {
    ${(props) =>
      defaultDividerStyles(
        props.dividerProps || {
          $gradient: false,
        }
      )};
  }
`;
