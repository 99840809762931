import styled from 'styled-components';

export const Root = styled.div`
  --border-width: 1px;
  --border-radius: ${(props) => props.theme.borderRadius.box};
  --color-gradient-start: ${(props) => props.theme.palette.divider.gradient.start};
  --color-gradient-end: ${(props) => props.theme.palette.divider.gradient.end};

  border: var(--border-width) solid transparent;
  border-radius: var(--border-radius);
  position: relative;

  &:hover {
    background:
      linear-gradient(to right, var(--color-gradient-start), var(--color-gradient-end))
        linear-gradient(to right, var(--color-gradient-start), var(--color-gradient-end)) no-repeat var(--border-radius)
        0,
      var(--border-radius) 100%;
    background-size: calc(100% - var(--border-radius) - var(--border-radius)) var(--border-width);
    padding: var(--border-width) 0;
    border: none;
    margin-left: var(--border-width);

    &:before,
    &:after {
      content: ' ';
      display: block;
      position: absolute;
      width: var(--border-radius);
      top: 0;
      bottom: 0;
    }

    &:before {
      left: 0;
      border: var(--border-width) solid var(--color-gradient-start);
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      border-right-color: transparent;
    }

    &:after {
      right: 0;
      border: var(--border-width) solid var(--color-gradient-end);
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      border-left-color: transparent;
    }
  }

  * {
    border-radius: var(--border-radius);
  }
`;
