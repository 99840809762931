import React from 'react';
import { RowGap } from '@Components/atoms/Row/Row';
import { PropsWithClassName } from '@Components/helper';
import { useBreakpoint } from '@Components/helper/useBreakpoint';
import AdFreestar from '@Components/molecules/AdFreestar/AdFreestar';
import { StyledRow } from './AdFreestarRow.styles';
interface Props extends PropsWithClassName {
  publisher?: string;
  placementName1?: string;
  placementName2?: string;
}
const freestarPublisher = process.env.NEXT_PUBLIC_FREESTAR_PUBLISHER;
const freestarPlacement1 = process.env.NEXT_PUBLIC_FREESTAR_PLACEMENT_1;
const freestarPlacement2 = process.env.NEXT_PUBLIC_FREESTAR_PLACEMENT_2;
const AdFreestarRow = ({
  publisher = freestarPublisher,
  placementName1 = freestarPlacement1,
  placementName2 = freestarPlacement2
}: Props): React.ReactElement => {
  const {
    isMobile
  } = useBreakpoint();
  if (publisher && placementName1 && placementName2) {
    return <StyledRow xsCenter rowGap={RowGap.Small} dataTestId={'ad-freestar-row-root'}>
        <AdFreestar publisher={publisher} placementName={placementName1} />
        {!isMobile && <AdFreestar publisher={publisher} placementName={placementName2} />}
      </StyledRow>;
  }
  return <></>;
};
export default AdFreestarRow;