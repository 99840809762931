'use client';

import { LinkProps } from 'next/dist/client/link';
import NextLink from 'next/link';
import { useRouter } from 'next/navigation';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useShallow } from 'zustand/react/shallow';
import i18nConfig from '@Root/i18nConfig';
import { isExternalUrl } from '@Components/helper/url';
import useUnsavedChangesStore from '@Store/useUnsavedChangesStore';
const Link = (props: Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof LinkProps> & LinkProps & {
  children?: React.ReactNode;
} & React.RefAttributes<HTMLAnchorElement>) => {
  const {
    i18n
  } = useTranslation();
  const router = useRouter();
  const currentLocale = i18n.language;
  const {
    isNavigationBlocked,
    setPendingRoute
  } = useUnsavedChangesStore(useShallow(state => ({
    isNavigationBlocked: state.isNavigationBlocked,
    setPendingRoute: state.setPendingRoute
  })));
  const getHref = () => {
    const href = typeof props.href === 'string' ? props.href : props.href.href;
    if (href && isExternalUrl(href)) {
      return props.href;
    }
    if (currentLocale === i18nConfig.defaultLocale) {
      return props.href;
    } else {
      return `/${currentLocale}${href}`;
    }
  };
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    const url = getHref().toString();

    // cancel navigation if e.g. form is dirty
    // todo: could be removed if next offers a better solution, see: https://github.com/vercel/next.js/discussions/41934#discussioncomment-12077414
    if (isNavigationBlocked) {
      setPendingRoute(url);
      return;
    }
    router.push(url);
  };
  return <NextLink {...props} href={getHref()} onClick={handleClick} data-sentry-element="NextLink" data-sentry-component="Link" data-sentry-source-file="Link.tsx" />;
};
export default Link;