import styled, { css } from 'styled-components';

import { LinkColor, LinkSize, LinkWeight } from '@Components/atoms/Link/Link';

import Link from '@Helpers/i18n/Link';
import { TextAlign, TextTransform } from '@Helpers/types/text';

export interface LinkProps {
  $weight: LinkWeight;
  $size: LinkSize;
  $color: LinkColor;
  $hideUnderline: boolean;
  $transform: TextTransform;
  $align: TextAlign;
}

export const defaultLinkStyles = (linkProps: LinkProps) => css`
  word-wrap: break-word;
  font-family: ${(props) => props.theme.font.primaryFamily};
  font-size: ${(props) => props.theme.font.text[linkProps.$weight][linkProps.$size].fontSize};
  font-weight: ${(props) => props.theme.font.text[linkProps.$weight][linkProps.$size].fontWeight};
  line-height: ${(props) => props.theme.font.text[linkProps.$weight][linkProps.$size].lineHeight};
  letter-spacing: ${(props) => props.theme.font.text[linkProps.$weight][linkProps.$size].letterSpacing};
  color: ${(props) => props.theme.palette.text[linkProps.$color]};
  transition: color ${(props) => props.theme.transition.theme};

  text-align: ${() => linkProps.$align};
  text-transform: ${() => linkProps.$transform};

  b,
  strong {
    font-weight: ${(props) => props.theme.font.text.semibold[linkProps.$size].fontWeight};
  }

  &:hover {
    text-decoration: ${(props) =>
      linkProps.$hideUnderline ? 'none' : 'underline ' + props.theme.palette.text[linkProps.$color]};
  }
`;

export const Root = styled(Link)<LinkProps>`
  ${(props) => defaultLinkStyles(props)}
`;
